// prefer default export if available
var preferDefault = function preferDefault(m) {
  return m && m["default"] || m;
};

exports.components = {
  "component---gatsby-theme-mdx-deck-src-templates-deck-js": function componentGatsbyThemeMdxDeckSrcTemplatesDeckJs() {
    return import("/vercel/workpath0/node_modules/gatsby-theme-mdx-deck/src/templates/deck.js"
    /* webpackChunkName: "component---gatsby-theme-mdx-deck-src-templates-deck-js" */
    );
  }
};