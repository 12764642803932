import { CodeSurfer, CodeSurferColumns, Step } from "code-surfer";
import { github } from "@code-surfer/themes";
import Code from "mdx-code";
import { Notes } from "mdx-deck";
import MonkeyLikesBanana from "../../../../../../MLB.png";
import DontHaveBanana from "../../../../../../DHB.png";
import ROTTENMAP from "../../../../../../ROTTENMAP.png";
import HTTP from "../../../../../../HTTPDIAGRAM.png";
import A from "../../../../../../ANALYSIS.png";
import GRAPHQL from "../../../../../../GRAPHQL.png";
import GA from "../../../../../../GQLANALYSIS.png";
import React from 'react';
export default {
  CodeSurfer: CodeSurfer,
  CodeSurferColumns: CodeSurferColumns,
  Step: Step,
  github: github,
  Code: Code,
  Notes: Notes,
  MonkeyLikesBanana: MonkeyLikesBanana,
  DontHaveBanana: DontHaveBanana,
  ROTTENMAP: ROTTENMAP,
  HTTP: HTTP,
  A: A,
  GRAPHQL: GRAPHQL,
  GA: GA,
  React: React
};